<template>
    <div class="sidebar">
        <div class="logo">
            <img src="./images/logo.svg" alt="">
        </div>
        <div class="sticky-top">
            <b-nav class="main-nav" vertical>
                <b-nav-item v-for="(page, index) in pages" :key="index" :to="page.route" @click="hideMenu">
                    <span class="icon">
                        <b-icon :icon="page.class"></b-icon>
                    </span>
                    <span class="text">{{ page.alias }}</span>
                </b-nav-item>
            </b-nav>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Sidebar',
        methods: {
            hideMenu(){
                const app = document.getElementById('app');
                app.classList.remove("active");
            }
        },
        data(){
            return {
                pages: [
                    {
                        route: {
                            name: 'forums'
                        },
                        class: 'calendar2-event',
                        alias: 'Список событий'
                    }, {
                        route: {
                            name: 'users'
                        },
                        class: 'people',
                        alias: 'Список пользователяей'
                    }, {
                        route: {
                            name: 'registration'
                        },
                        class: 'person-plus',
                        alias: 'Регистрация на событие'
                    }, {
                        route: {
                            name: 'unban'
                        },
                        class: 'person-check',
                        alias: 'Разблокировать пользователя'
                    }, {
                        route: {
                            name: 'admins'
                        },
                        class: 'person-square',
                        alias: 'Администраторы'
                    }, {
                        route: {
                            name: 'claim'
                        },
                        class: 'info-circle',
                        alias: 'Жалобы пользователей'
                    }, {
                        route: {
                            name: 'billings'
                        },
                        class: 'cash',
                        alias: 'Транзакции'
                    }, {
                        route: {
                            name: 'icons'
                        },
                        class: 'images',
                        alias: 'Менеджер иконок'
                    }, {
                        route: {
                            name: 'interests'
                        },
                        class: 'heart-fill',
                        alias: 'Менеджер интересов'
                    }
                ]
            }
        }
    }
</script>

<style lang="scss">
    .sidebar{
        width: 255px;
        background: #4b4b55;
        padding: 34px 10px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        overflow-y: auto;
    }
    .sticky-top{
        padding-top: 30px;
    }
    .logo{
        border-bottom: 1px solid rgba(219, 219, 219, 0.4);
        padding-bottom: 10px;
        margin-bottom: 0;
        text-align: center;
        img{
            max-width: 100%;
        }
    }
    .main-nav{
        .nav-item{
            margin-bottom: 10px;
            .nav-link{
                color: #fff;
                font-size: 16px;
                padding: 8px;
                border-radius: 4px;
                display: flex;
                .icon{
                    width: 22px;
                    flex: none;
                }
                .text{
                    flex: 1;
                    padding-left: 5px;
                }
                &:hover,&.router-link-active{
                    background: #2c68b8;
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        .sidebar{
            left: -100%;
            z-index: 5;
            transition: left 0.2s;
        }
    }
</style>