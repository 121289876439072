<template>
    <div class="main-wrapper">
        <app-sidebar />
        <div class="content">
            <app-header />
            <router-view />
        </div>
    </div>
</template>

<script>
    import AppHeader from '@/components/app-header'
    import AppSidebar from '@/components/app-sidebar'

    export default {
        name: 'App',
        components: {
            AppSidebar,
            AppHeader
        },
    }
</script>

<style lang="scss">
    .main-wrapper{
        padding: 0 0 0 0;
        min-width: 320px;
        width: 100%;
        position: relative;
        min-height:100%;
        background: #f0f0f0;
    }
    .content{
        margin-left: 255px;
        padding: 0 20px 20px;
        overflow-x: hidden;
        min-height: 100vh;
    }
    @media screen and (max-width: 991px) {
        .content{
            margin: 0;
            padding: 0 10px 10px;
        }
        #app.active{
            .content{
                &:before{
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,0.5);
                    position: absolute;
                    top:0;
                    left: 0;
                    z-index: 4;
                }
            }
        }
    }
</style>
