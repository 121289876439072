<template>
    <header>
        <div class="page-title">
            <span class="page-title__txt">{{title}}</span>
            <button class="toggle-btn" @click="toggleMenu">
                <span></span>
            </button>
        </div>
        <div class="user-switcher">
            <b-dropdown class="user-switcher-toggle" size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <img v-if="avatarPath" class="user-ava" :src="avatarPath" alt="">
                    <img v-else class="user-ava" src="./images/default-ava.svg" alt=""> {{ firstName }} {{ lastName }}
                </template>
                <b-dropdown-item href="#" @click="logout">Logout</b-dropdown-item>
            </b-dropdown>
        </div>
    </header>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "HeaderRow",
        computed: {
            ...mapGetters([
                'avatarPath',
                'firstName',
                'lastName'
            ]),
            title(){
                const name = this.$route.name;
                for (let i = 0; i < this.pages.length; ++i) {
                    let page = this.pages[i];
                    if(page.name === name){
                        return page.title
                    }
                }
                return ''
            }
        },
        methods: {
            toggleMenu(){
                const app = document.getElementById('app');
                app.classList.toggle("active");
            },
            logout(e) {
                e.preventDefault();
                this.$store.dispatch('signOut').then(() => {
                    this.$router.push({name:'signup', replace: true})
                })
            },
        },
        data(){
            return {
                pages: [
                    {
                        name: 'forums',
                        title: 'Список событий'
                    }, {
                        name: 'forum-add',
                        title: 'Создание ивента'
                    }, {
                        name: 'forum-details',
                        title: 'Детали ивента'
                    }, {
                        name: 'forum-edit',
                        title: 'Редактирование ивента'
                    }, {
                        name: 'forum-program',
                        title: 'Программа ивента'
                    }, {
                        name: 'users',
                        title: 'Список пользователей'
                    }, {
                        name: 'user-add',
                        title: 'Добавление нового пользователя'
                    }, {
                        name: 'user-details',
                        title: 'Информация о пользователе'
                    }, {
                        name: 'user-edit',
                        title: 'Редактирование информации о пользователе'
                    }, {
                        name: 'icons',
                        title: 'Менеджер иконок'
                    }, {
                        name: 'registration',
                        title: 'Регистрация на событие'
                    }, {
                        name: 'unban',
                        title: 'Разблокировать пользователя'
                    }, {
                        name: 'admins',
                        title: 'Администраторы'
                    }, {
                        name: 'claim',
                        title: 'Жалобы пользователей'
                    }, {
                        name: 'interests',
                        title: 'Добавление интересов'
                    }, {
                        name: 'billings',
                        title: 'Транзакции'
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
        border-bottom: 1px solid #dbdbdb;
        margin-bottom: 20px;
    }
    .page-title{
        font-size: 24px;
        color:#232323;
    }
    .toggle-btn{
        display: none;
        border: 2px solid #013274;
        border-radius: 4px;
        color: #013274;
        background: none;
        width: 42px;
        height: 42px;
        position: relative;
        padding: 0;
        span{
            left: 6px;
        }
        span, span:before,span:after{
            content: "";
            display: block;
            height: 0;
            border-radius: 3px;
            transition: 0.2s;
            position: absolute;
            top: 18px;
            width: 26px;
            border-bottom: 3px solid;
        }
        span:before{
            top: 8px;
        }
        span:after{
            top: -8px;
        }
    }
    .user-ava{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
    }
    @media screen and (max-width: 991px) {
        header{
            padding: 10px 0;
        }
        .page-title__txt{
            display: none;
        }
        .toggle-btn{
            display: inline-block;
        }
    }

</style>

<style lang="scss">
    @media screen and (max-width: 991px) {
        #app{
            &.active{
                .sidebar{
                    left: 0;
                }
                .toggle-btn{
                    position: absolute;
                    top: 15px;
                    left: 255px;
                    z-index: 5;
                    /*border-color: #fff;*/
                    span{
                        border-color: transparent;
                        &:before{
                            transform: rotate(45deg);
                            top: 0;
                            border-color: #fff;
                        }
                        &:after{
                            transform: translatey(-10px) rotate(-45deg);
                            top: 10px;
                            border-color: #fff;
                        }
                    }
                }
            }
        }
    }
</style>